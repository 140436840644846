import React from 'react';
import Layout from '../components/Layout';
import profilePic from '../../content/assets/profile-pic.png';

const About = () => {
    return (
        <Layout>
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.75rem' }}>
                    <img style={{ borderRadius: '45px' }} width={`100px`} height={`100px`} alt="tyroprogrammer" src={profilePic} />
                </div>
                <h4 style={{ marginTop: '1.75rem' }}>Who?</h4>
                <p>I am a software engineer. After working several years as 
                    a software engineer with several companies,
                    I still consider myself a beginner in this field.
                    Everyday I stumble when I come across something 
                    new just like a beginner would.
                </p>

                <h4>What?</h4>
                <p>What does the word “tyro” mean anyway, you ask?</p>

                <blockquote style={{ whiteSpace: 'pre' }}>
                { 
`ty·ro
/ˈtīrō/
noun

a beginner in learning. novice.
    https://www.merriam-webster.com/dictionary/tyro`
                }
                </blockquote>

                <h4>Why?</h4>
                <p>Honestly - primarily this blog is just for myself. As I learn something new, 
                    I wanted to write it down as a reference for my future self because, 
                    you see, I still consider myself a beginner in this field and I have a terrible memory.
                    But if any of the posts in here is useful to anybody else, I am happy it helped.
                </p>

                <div><a href="mailto:tyroprogrammer.info@gmail.com">Email me!</a></div>
            </div>
        </Layout>
    )
}

export default About;